<template>
    <div class="laporan-peserta text-white">

        <div class="header-content">
            <div class="container mt-3 px-5">
                <div class="card">
                    <div class="mx-4 my-4 text-left">
                        <div class="row">
                            <div class="col">
                                <div class="d-flex align-items-center ml-4">
                                    <img src="@/assets/images/users/3.png" alt="" class="rounded-circle">
                                    <div class="ml-3">
                                        <h4>{{dataResult.user_name}}</h4>
                                        <div class="d-flex align-items-center">
                                            <img src="@/assets/images/logo/piala.svg" alt="" class="champ-logo">
                                            <p class="m-0 ml-2 text-success font-weight-bold point">{{dataResult.score}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col d-flex align-items-center justify-content-end">
                                <!-- <button class="btn btn-outline-purple btn-md mr-4">Export to Excel</button> -->
                            </div>
                        </div>
                        <div class="row mt-3 d-flex align-items-center">
                            <div class="col-md-1 d-flex align-items-center ml-4">
                                <div class="d-flex align-items-center mt-2">
                                    <b-icon icon="check-circle"></b-icon>
                                    <p class="m-0 text-white ml-1">Correct</p>
                                </div>
                            </div>
                            <div class="col-10 ml-3">
                                <div class="custom-prog bg-success" :style="{height: auto, width: (dataResult.data_true_answer.length / dataResult.data_all_answer.length) * 100 + '%', borderRadius: '0px 50px 50px 0px'}">
                                    <p class="text-white m-0 ml-2">{{dataResult.data_true_answer.length}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex align-items-center">
                            <div class="col-1 d-flex align-items-center ml-4">
                                <div class="d-flex align-items-center mt-2">
                                    <b-icon icon="x-circle"></b-icon>
                                    <p class="m-0 text-white ml-1">Incorrect</p>
                                </div>
                            </div>
                            <div class="col-md-10 ml-3">
                                <div class="custom-prog bg-danger" :style="{height: auto, width: (dataResult.data_false_answer.length ? dataResult.data_false_answer.length : 0 / dataResult.data_all_answer.length ? dataResult.data_all_answer.length : 0) * 100 + '%', borderRadius: '0px 50px 50px 0px'}">
                                    <p class="text-white m-0 ml-2">{{ dataResult.data_false_answer.length ? dataResult.data_false_answer.length : 0}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex justify-content-between px-2 py-3">
                    <div class="question-total d-flex align-items-center">
                        <font-awesome-icon icon="fa-regular fa-file-alt" />
                        <h5 class="font-weight-normal ml-2">{{dataQuestion.length}} Questions</h5>
                    </div>
                    <div class="d-flex ml-3 align-items-center">
                        <div class="mr-2">
                            <p class="m-0">Sort By :</p>
                        </div>
                        <div class="text-left">
                            <select name="" id="filter" class="form-control mr-3">
                                <option value="">All</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div v-for="(data,index) in dataQuestion" :key="index" v-katex:auto>
                    <div class="card my-2">
                        <div>
                            <div class="d-flex align-items-center mx-4 my-3">
                                <div class="col-md-9 d-flex align-items-center text-left">
                                    <h5 class="font-weight-normal">Questions {{ index + 1 }} </h5>
                                    <div v-if="data.data_answer.length != 0">
                                        <div v-if="data.data_answer[0].answer_result == true" class="d-flex align-items-center ml-2">
                                            <b-icon icon="check-circle" class="text-success"></b-icon>
                                            <p class="m-0 text-none ml-1 text-success">Correct</p>
                                        </div>
                                        <div v-else class="d-flex align-items-center ml-2">
                                            <b-icon icon="x-circle" class="text-danger"></b-icon>
                                            <p class="m-0 text-none ml-1 text-danger">Incorrect</p>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-md-3 d-flex text-right">
                                    <div class="form-point mr-3">
                                        <p class="mb-0 my-1 mx-3">{{ data.point }} Point</p>
                                    </div>
                                    <div class="form-point">
                                        <p class="mb-0 my-1 mx-3">{{ data.duration }} Second</p>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex align-items-center px-4 py-3">
                                <img v-if="data.image_url != null && data.image_url != ''" :src="data.image_url" :alt="data.image_url" class="img-fluid mb-3"/>
                                <audio v-if="data.voice != null && data.voice != ''" :src="data.voice" controls></audio>
                                <div v-if="data.video != null && data.video != ''" class="text-center w-100">
                                    <iframe width="50%" height="300" :src="`https://www.youtube.com/embed/${getYoutubeVideoId(data.video)}`" title="YouTube video player" class="mt-2" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                                <div class="w-100">
                                    <div v-if="data.question_type === 'Code' || data.question_type === 'Code Test'" class="mb-3 text-left">
                                        <CodeEditor
                                            :language="data.code_language || 'py'"
                                            :value="data.code"
                                            readonly
                                        />
                                    </div>
                                    <p class="text-break text-left mb-0"><span v-html="data.question"></span></p>
                                </div>
                                <!-- <img v-if="data.image != null" :src="require(`@/assets/images/${data.image}`)" alt="" class="mr-3"> -->
                                <!-- <p class="font-weight-normal text-break d-flex"><span class="text-break" v-html="data.question"></span></p> -->
                            </div>
                            <div class="text-left px-4 py-3">
                                <div class="answer">
                                    <div class="d-flex">
                                        <div class="col-12 text-left">
                                            <div class="row px-3">
                                                <p class="text-secondary col-6">Participant's answer</p>
                                                <p class="text-secondary col-6">Answer key</p>
                                            </div>
                                            <div class="row px-3" v-if="data.data_answer.length != 0">
                                                    <div class="col-6">
                                                        <div v-if="data.data_answer[0].answer == '1'" class="d-flex">
                                                            <div>
                                                                <span :class="data.data_answer[0].answer == data.correct_answer ? 'correct-active' : 'incorrect-active'"></span>
                                                            </div>
                                                            <p class="mb-0 text-break d-flex"><span class="text-break ml-1" v-html="data.option_1"></span></p>
                                                        </div>

                                                        <div v-if="data.data_answer[0].answer == '2'" class="d-flex">
                                                            <div>
                                                                <span :class="data.data_answer[0].answer == data.correct_answer ? 'correct-active' : 'incorrect-active'"></span>
                                                            </div>
                                                            <p class="mb-0 text-break d-flex"><span class="text-break ml-1" v-html="data.option_2"></span></p>
                                                        </div>

                                                        <div v-if="data.data_answer[0].answer == '3'" class="d-flex">
                                                            <div>
                                                                <span :class="data.data_answer[0].answer == data.correct_answer ? 'correct-active' : 'incorrect-active'"></span>
                                                            </div>
                                                            <p class="mb-0 text-break d-flex"><span class="text-break ml-1" v-html="data.option_3"></span></p>
                                                        </div>

                                                        <div v-if="data.data_answer[0].answer == '4'" class="d-flex">
                                                            <div>
                                                                <span :class="data.data_answer[0].answer == data.correct_answer ? 'correct-active' : 'incorrect-active'"></span>
                                                            </div>
                                                            <p class="mb-0 text-break d-flex"><span class="text-break ml-1" v-html="data.option_4"></span></p>
                                                        </div>

                                                        <!-- <div v-else class="d-flex">
                                                            <div>
                                                                <span class="incorrect-active"></span>
                                                            </div>
                                                            <p class="mb-0 text-break d-flex"><span class="text-break ml-1">-</span></p>
                                                        </div> -->

                                                    </div>

                                                    <div class="col-6">
                                                        <div v-if="data.correct_answer == '1'" class="d-flex">
                                                            <div>
                                                                <span class="correct-active"></span>
                                                            </div>
                                                            <p class="mb-0 text-break d-flex"><span class="text-break ml-1" v-html="data.option_1"></span></p>
                                                        </div>
                                                        <div v-if="data.correct_answer == '2'" class="d-flex">
                                                            <div>
                                                                <span class="correct-active"></span>
                                                            </div>
                                                            <p class="mb-0 text-break d-flex"><span class="text-break ml-1" v-html="data.option_2"></span></p>
                                                        </div>
                                                        <div v-if="data.correct_answer == '3'" class="d-flex">
                                                            <div>
                                                                <span class="correct-active"></span>
                                                            </div>
                                                            <p class="mb-0 text-break d-flex"><span class="text-break ml-1" v-html="data.option_3"></span></p>
                                                        </div>
                                                        <div v-if="data.correct_answer == '4'" class="d-flex">
                                                            <div>
                                                                <span class="correct-active"></span>
                                                            </div>
                                                            <p class="mb-0 text-break d-flex"><span class="text-break ml-1" v-html="data.option_4"></span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {getQuizEventsDetailResultQuestions} from '@/services/quiz-events/quiz-events.service'
import {getQuizResultsByResultId} from '@/services/quiz-results/quiz-results.service'
import $ from "jquery";

import CodeEditor from '@/components/code-editor/CodeEditor';

export default {
    components: {
        CodeEditor,
    },
    data() {
        return{
            dataQuestion: [],
            dataResult: [],
            dummyData : [
                {
                    no : 1,
                    pertanyaan : 'Siapakah tokoh yang ada pada gambar disamping?',
                    jawabanUser : 'Summer',
                    jawabanBenar : 'Ir.Soekarno',
                    poin : '10',
                    waktu : '20 Seconds',
                    image : 'soekarno.png'
                },
                {
                    no : 2,
                    pertanyaan : 'Ibu kota Jawa Barat adalah Bekasi',
                    jawabanUser : 'Benar',
                    jawabanBenar : 'Salah',
                    poin : '0',
                    waktu : '20 Seconds',
                    image : null
                },
                {
                    no : 3,
                    pertanyaan : 'Siapakah tokoh yang ada pada gambar disamping?',
                    jawabanUser : 'Ir.Soekarno',
                    jawabanBenar : 'Ir.Soekarno',
                    poin : '10',
                    waktu : '25 Seconds',
                    image : 'soekarno.png'
                },
                {
                    no : 4,
                    pertanyaan : 'Ibu kota Jawa Barat adalah Bekasi',
                    jawabanUser : 'Benar',
                    jawabanBenar : 'Salah',
                    poin : '0',
                    waktu : '30 Seconds',
                    image : null
                },
            ]
        }
    },
    mounted(){
        setTimeout(() => {
            $("div.laporan-peserta").parent("").addClass("m-0");
        }, 500);
        const event_id = this.$route.query.event_id
        const quiz_result_id = this.$route.query.quiz_result_id
        this.getDataResult(quiz_result_id)
        this.getDataQuestions(event_id, quiz_result_id)
        this.setSideBar();
    },
    methods: {

        setSideBar() {
            this.$store.commit("IS_SIDEBAR_ACTIVE", false);
        },

        getDataResult(quiz_result_id){
            getQuizResultsByResultId(quiz_result_id).then((response)=>{
                if(response.status){
                    this.$vs.loading.close();
                    this.dataResult = response.data[0];
                } else {
                    this.$vs.loading.close();
                    this.dataResult = [];
                }

            }).catch((err)=>{
                this.$vs.loading.close();
                console.log("data err", err)
            })
        },
        getDataQuestions(event_id, quiz_result_id){
            getQuizEventsDetailResultQuestions(event_id, quiz_result_id).then((response)=>{
                if(response.status){
                    this.$vs.loading.close();
                    this.dataQuestion = response.data;
                } else {
                    this.$vs.loading.close();
                    this.dataQuestion = [];
                }

            }).catch((err)=>{
                this.$vs.loading.close();
                console.log("data err", err)
            })
        },

        getYoutubeVideoId(url) {
            let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
            let match = url.match(regExp);
            return (match && match[7].length === 11) ? match[7] : '';
        },
    }
}
</script>
<style scoped>
    .laporan-peserta .card{
        border-radius: 15px;
    }
    .laporan-peserta{
       padding-top: 80px;
    }

    .header-content img{
        width: 60px;
        height: 60px;
    }

    .header-content .point{
        font-size: 20px;
    }

    .champ-logo{
        width: 19px !important;
        height: 19px !important;
    }

    .btn-impor{
        border-radius: 15px;
    }

    .circle-svg{
        width: 14px !important;
        height: 14px !important;
    }

    .text-none{
        color: #585859;
        font-size: 14px !important
    }

    .correct-active{
        height: 1.125rem;
        width: 1.125rem;
        border-radius: 50%;
        display: inline-block;
        background-color: #40BE45;
    }

    .incorrect-active{
        height: 1.125rem;
        width: 1.125rem;
        border-radius: 50%;
        display: inline-block;
        background-color: #EB5757;
    }

    .btn-outline-purple{
        color: #6D75F6;
        border: 2px solid #6D75F6;
        border-radius: 20px;
        background: transparent;
    }

    .form-control{
        background-color: #222222;
        color: white;
        border-color: #383838;
    }

    .form-point{
        width: auto;
        background-color:#2D2D2D;
        border-radius:4px ;
    }
</style>