<template>
    <div class="report-polling container-fluid">
        <h2 class="text-left">Laporan Polling - {{dataReport.polling_name}}</h2>
        <div class="mt-2 container-fluid">
            <div class="row mb-2">
                <div class="col-md-8">
                    <div class="card">
                        <div class="car-body">
                            <div class="d-flex align-items-center justify-content-between mx-4 mt-3">
                                <h3>Grafik Users</h3>
                                <div class="card-users px-2 py-2">
                                    <div class="d-flex align-items-center">
                                        <img src="@/assets/images/icons/user-rgb.svg" alt="user" class="mr-2">
                                        <div>
                                            <h2>{{dataPollingQuestion.total_all_polling}}</h2>
                                            <p class="mb-0">Total Users</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="my-2">
                                <apexchart type="area" height="350" :options="chartOptions" :series="series"></apexchart>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card ml-3 mb-4">
                        <div class="card-body">
                            <div class="row mb-2 align-items-center">
                                <div class="col-md-4">
                                    <img src="@/assets/images/icons/excited.svg" alt="">
                                    <p class="mb-0">{{dataPollingQuestion.option_3}}</p>
                                </div>
                                <div class="col-md-8">
                                    <div class="text-left mr-2">
                                        <h1 class="text-success">{{dataPollingQuestion.total_option_3}}</h1>
                                        <p class="text-right mb-1">{{precentage(dataPollingQuestion.total_option_3)}}%</p>
                                        <b-progress height="0.6rem" :max="dataPollingQuestion.total_all_polling" :value="dataPollingQuestion.total_option_3" variant="success"></b-progress>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card ml-3 mb-4">
                        <div class="card-body">
                            <div class="row mb-2 align-items-center">
                                <div class="col-md-4">
                                    <img src="@/assets/images/icons/notbad.svg" alt="">
                                    <p class="mb-0">{{dataPollingQuestion.option_2}}</p>
                                </div>
                                <div class="col-md-8">
                                    <div class="text-left mr-2">
                                        <h1 class="text-warning">{{dataPollingQuestion.total_option_2}}</h1>
                                        <p class="text-right mb-1">{{precentage(dataPollingQuestion.total_option_2)}}%</p>
                                        <b-progress height="0.6rem" :max="dataPollingQuestion.total_all_polling" :value="dataPollingQuestion.total_option_2" variant="warning"></b-progress>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card ml-3">
                        <div class="card-body">
                            <div class="row mb-2 align-items-center">
                                <div class="col-md-4">
                                    <img src="@/assets/images/icons/dissapointed.svg" alt="">
                                    <p class="mb-0">{{dataPollingQuestion.option_1}}</p>
                                </div>
                                <div class="col-md-8">
                                    <div class="text-left mr-2">
                                        <h1 class="text-danger">{{dataPollingQuestion.total_option_1}}</h1>
                                        <p class="text-right mb-1">{{precentage(dataPollingQuestion.total_option_1)}}%</p>
                                        <b-progress height="0.6rem" :max="dataPollingQuestion.total_all_polling" :value="dataPollingQuestion.total_option_1" variant="danger"></b-progress>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-3">
            <div class="card">
                <div class="card-body">
                    <h3 class="text-left">List Users</h3>
                    <div class="d-flex justify-content-between my-3">
                        <div class="d-inline-flex align-items-center">
                            Show&nbsp;
                            <b-form-select
                                class="bg-grey"
                                v-model="perPage"
                                :options="pageOptions"
                            ></b-form-select
                            >&nbsp;entries
                        </div>
                        <button type="button" class="btn btn-light bg-white"> <img src="@/assets/images/logo/excel.png" alt="excel" class="excel-logo"> Export to excel</button>
                    </div>
                    <b-table hover :items="resultPollingTable" :fields="fields" id="user-table" :per-page="perPage" :current-page="currentPage">
                        <template v-slot:cell(no)="data">
                            {{ currentPage == 1 ? data.index + 1 : getNumber(data.index + 1) }}
                        </template>

                        <template v-slot:cell(image)="data">
                            <img v-if="data.item.data_poller.data_user.picture != null" :src="data.item.data_poller.data_user.picture" alt="user" class="user-img rounded-circle">
                            <img v-else :src="'https://ui-avatars.com/api/?background=405DC3&color=fff&name=' + data.item.data_poller.user_name" alt="user" class="user-img rounded-circle">
                        </template>

                        <template v-slot:cell(namaLengkap)="data">
                            <span>{{ data.item.data_poller.user_name }}</span>
                        </template>

                        <template v-slot:cell(email)="data">
                            <span>{{ data.item.data_poller.email }}</span>
                        </template>

                        <template v-slot:cell(statusPolling)="data">
                            <span :class="data.item.polling_choice=='3'?'text-success font-weight-bold':data.item.polling_choice=='2'?'text-warning font-weight-bold':data.item.polling_choice=='1'?'text-danger font-weight-bold':'font-weight-bold'"> {{data.item.polling_choice == '1' ? dataPollingQuestion.option_1 : data.item.polling_choice == '2' ? dataPollingQuestion.option_2 : dataPollingQuestion.option_3}}</span>
                        </template>
                    </b-table>
                    <div class="pagination-seeall container-md mt-3 d-flex justify-content-between">
                        <p>Showing {{resultPollingTable.length}} of {{resultPollingTable.length}} entries</p>
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                            aria-controls="user-table"
                            prev-text="Prev"
                            next-text="Next"
                        ></b-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getReportPollings} from '@/services/pollings/pollings.service'

export default {
    data(){
        return {
            series: [{
                name: 'Users',
                data: [31, 40, 28, 51, 42, 109]
            }],
            chartOptions: {
                chart: {
                    height: 350,
                    type: 'area',
                    toolbar: {
                        show: false
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                curve: 'smooth'
                },
                xaxis: {
                    type: 'datetime',
                    categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
                },
                tooltip: {
                    x: {
                            format: 'HH:mm'
                        },
                    },
            },
            itemsDummy: [
                {
                    no : 1,
                    image : '1.png',
                    namaLengkap : 'Dadun Kasmaran',
                    email : "dadun123@gmail.com",
                    statusPolling : "Excited"
                },
                {
                    no : 2,
                    image : '1.png',
                    namaLengkap : 'Dadun Kasmaran',
                    email : "dadun123@gmail.com",
                    statusPolling : "Not Bad"
                },
                {
                    no : 3,
                    image : '1.png',
                    namaLengkap : 'Dadun Kasmaran',
                    email : "dadun123@gmail.com",
                    statusPolling : "Dissapointed"
                },
                {
                    no : 4,
                    image : '1.png',
                    namaLengkap : 'Dadun Kasmaran',
                    email : "dadun123@gmail.com",
                    statusPolling : "Excited"
                },
                {
                    no : 5,
                    image : '1.png',
                    namaLengkap : 'Dadun Kasmaran',
                    email : "dadun123@gmail.com",
                    statusPolling : "Excited"
                },
                {
                    no : 6,
                    image : '1.png',
                    namaLengkap : 'Dadun Kasmaran',
                    email : "dadun123@gmail.com",
                    statusPolling : "Excited"
                },
            ],
            pageOptions: [5, 10, 25, 50, 100],
            perPage: 5,
            currentPage : 1,
            fields: [
                {
                    key: "no",
                    label: 'No',
                    sortable: true
                },
                {
                    key: "image",
                    sortable: true
                },
                {
                    key: "namaLengkap",
                    sortable: true
                },
                {
                    key: "email",
                    sortable: true
                },
                {
                    key: "statusPolling",
                    sortable: true
                },
            ],
            dataReport : [],
            dataPollingQuestion : [],
            resultPollingTable : [],
        }
    },
    computed: {
      rows() {
        return this.resultPollingTable.length
      }
    },
    mounted(){
        this.getReport()
    },
    methods: {
        getReport() {
            getReportPollings()
            .then((response) => {
                if (response.status && response.status == true){
                    this.dataReport = response.data[0]
                    this.dataPollingQuestion = this.dataReport.data_polling_questions[0]
                    this.resultPollingTable = this.dataPollingQuestion.data_result_all_polling
                }
            })
        },

        precentage(value){
            var precentage = (value/this.dataPollingQuestion.total_all_polling)*100

            var finalValue = Math.ceil(precentage)
            
            return finalValue
        },
        getNumber(index) {
            return this.perPage + index;
        },
    }
}
</script>
<style scoped>
.card {
    border-radius: 20px !important;
    border: 0px !important;
}

h3 {
    font-size: 26px;
}

h1 {
    font-size: 40px;
}

.card-users{
    border: 1px solid #7280FF;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(215,219,255,1) 100%);
    height: 4.1rem;
    border-radius: 10px;
}

.card-users h2, .card-users p{
    color: #7280FF;
}

.card-users p {
    font-size: 10px;
}

.excel-logo{
    width: 20px;
    height: 20px;
}

.bg-grey{
    background-color:#EFEFEF;
}

.user-img {
    width: 35px;
    height: 35px;
}
</style>